import React from "react"

import { Typography, Grid, Col, Row } from "antd"

import { FormattedMessage } from "../shared"
import { RegisterForm } from "../form"

import "../shared/global.styles.scss"

const { Title, Text, Paragraph } = Typography
const { useBreakpoint } = Grid

const url = `${process.env.DELETE_ACCOUNT_ENDPOINT}`

export const LandingContainer: React.FC = () => {

  const screen = useBreakpoint()

  return (
    <>
      <div className="landing__container" style={ screen.lg ? {padding: "0 48px"} : {padding: "0"}}>
        {/* TITLE */}
        <Row>
        <Col xs={24} className="landing__containerTitle">
          

          <Paragraph style={ screen.lg ? { fontSize: "18px", marginTop: "15rem", paddingRight: "4rem" } : { fontSize: "22px", marginTop: "15rem" }}>
            <FormattedMessage id="app.content.info.p1" />
            <br/>
            <FormattedMessage id="app.content.info.p2" />
            <br/>
            <ul style={{ paddingLeft: "2rem", marginTop: "2rem" }}>
              <li>
                <strong>Manage your Device</strong>
                <br/>
                One stop shop! View device details in one location.
              </li>
            </ul>
            <ul style={{ paddingLeft: "2rem", marginTop: "2rem" }}>
              <li>
                <strong>Reset your Device’s Password</strong>
                <br/>
                Easily reset your DVR / NVR password.
              </li>
            </ul>
          </Paragraph>
        </Col>
        </Row>

        {/* REGISTER FORM */}
        {screen.xl ? 
          <div className="landing__containerRegister">
            <RegisterForm />
          </div>
        : null}
      </div>
    </>
  )
}
