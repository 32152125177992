import React, { useEffect } from "react"

import { useSelector } from "react-redux"
import { AppState, UserState } from "../state"

import { LandingContainer } from "../components/landing/landing-container"
import SEO from "../components/shared/seo"

const LandingPage: React.FC = () => {
  const { user } = useSelector<AppState, UserState>((state) => state.user)

  // To redirect the user if he/she is logged in and navigates to the landing URL
  useEffect(() => {
    if (user.Username) {
      window.location.replace("/")
    }
  }, [])

  return (
    <>
      <SEO title="Landing" />
      {user.Username ? null : <LandingContainer />}
    </>
  )
}

export default LandingPage
